<template>
  <div class="corp">
    <div style="max-width: 1200px" class="mx-auto">
      <v-container>
        <v-row>
          <v-col><a href="" style="font-size: small">Sobre</a> </v-col>
          <v-col><a href="" style="font-size: small">Blog</a> </v-col>
          <v-col><a href="" style="font-size: small">Carreiras</a> </v-col>
          <v-col><a href="" style="font-size: small">Ajuda</a> </v-col>
          <v-col><a href="" style="font-size: small">API</a> </v-col>
          <v-col><a href="" style="font-size: small">Privacidade</a> </v-col>
          <v-col><a href="" style="font-size: small">Termos</a> </v-col>

          <v-col><a href="" style="font-size: small">Hashtags</a> </v-col>
          <v-col><a href="" style="font-size: small">Localizações</a></v-col>
        </v-row>
      </v-container>
    </div>

    <p>© 2021 Peet's App do Infous</p>
    <p><img :src="logo" alt="Infous" srcset="" width="64" /></p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data: () => ({
    logo: require("@/assets/logo.png"),
    dark: false,
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.corp {
  text-align: center;
  color: grey;
}
</style>
